<script>
import {mapActions} from "vuex";

export default {
  data() {
    return {
      stage: 1
    }
  },
  methods: {

    ...mapActions(['switchConfirmPopup']),
    reload() {
      window.location.reload();
    }
  }
}
</script>

<template>
  <div class="popup-confirm">

    <div v-if="stage === 1" class="content">
      <div class="popup-confirm__icon">
        <svg width="24" height="29" viewBox="0 0 24 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M18.7076 3.43667L18.2852 7.73254H6.10789L5.64232 3.46058C5.56072 2.731 6.02626 2.05429 6.73184 1.8623L7.73505 1.59348C10.7062 0.791897 13.8357 0.801529 16.7972 1.62711L17.6324 1.8623C18.3236 2.05429 18.7748 2.72149 18.7076 3.43667Z"
            stroke="#E482FF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
          <path d="M12.2371 2.87964V5.85554" stroke="#E482FF" stroke-width="1.5" stroke-miterlimit="10"
                stroke-linecap="round"/>
          <path d="M13.7154 4.36792H12.3618" stroke="#E482FF" stroke-width="1.5" stroke-miterlimit="10"
                stroke-linecap="round"/>
          <path d="M12.2371 4.36792H10.7395" stroke="#E482FF" stroke-width="1.5" stroke-miterlimit="10"
                stroke-linecap="round"/>
          <path
            d="M4.65823 7.34863C3.61665 8.89419 3.01665 10.7517 3.01665 12.7293C3.01665 13.6029 3.0503 14.5196 3.12709 15.4844C3.35269 18.2971 3.89503 20.7595 4.51901 22.8042C4.9126 24.1098 3.70307 25.377 2.3975 24.9786V24.9786C1.96164 24.8492 1.51092 25.1443 1.70836 25.5539C2.06566 26.2951 2.82431 27.191 4.42784 27.4458L8.81974 26.5962C8.96374 26.5386 9.07893 26.457 9.17493 26.3562C9.24692 26.289 9.30453 26.2026 9.35732 26.1162C9.39092 26.0586 9.41489 26.0009 9.43888 25.9385C9.46288 25.8761 9.47728 25.8138 9.49168 25.7514C9.52528 25.593 9.53972 25.425 9.53972 25.2762C9.53972 25.209 9.53491 25.1466 9.53011 25.089C9.53011 25.0362 9.5253 24.9833 9.5157 24.9401C9.5013 24.8201 9.48211 24.7098 9.46771 24.633"
            stroke="#E482FF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
          <path
            d="M15.3138 24.6086C15.3138 24.623 15.309 24.6375 15.309 24.6471C15.3042 24.6807 15.3042 24.7046 15.2994 24.743C15.2946 24.7718 15.2898 24.7958 15.2898 24.8294C15.285 24.863 15.285 24.9014 15.2802 24.9398C15.2514 25.4774 15.333 26.303 16.0434 26.591L20.4113 27.4358C21.9963 27.1739 22.7463 26.2852 23.0998 25.5491C23.2979 25.1365 22.8468 24.8432 22.408 24.9734V24.9734C22.2928 25.0118 22.024 25.0695 21.7408 25.0407C21.5872 25.0263 21.4432 24.9878 21.3136 24.935C20.6848 24.671 20.3105 24.0087 20.3393 23.3223L20.4065 21.7719L21.1553 19.2951C21.3856 18.1096 21.568 16.8328 21.6784 15.4745C21.7552 14.5097 21.7888 13.5929 21.7888 12.7193C21.7888 10.521 21.0401 8.47624 19.7921 6.83948"
            stroke="#E482FF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
          <path
            d="M10.6207 10.8927C10.5229 10.6897 9.96317 10.6985 9.87734 10.9069C9.38349 12.1057 8.25699 13.9779 5.81982 15.0669V15.3452C5.81982 16.0508 5.87739 16.7468 6.02138 17.438C6.02618 17.4572 6.03099 17.4763 6.03099 17.4955C6.49178 19.7035 7.36534 21.4987 8.22931 22.8667C8.53651 23.3035 8.98294 23.8554 9.59252 24.3498C10.0869 24.7482 10.6821 25.113 11.4021 25.3482C11.6181 25.4202 11.8292 25.473 12.0356 25.5114C12.1652 25.5306 12.2996 25.5401 12.4436 25.5401C12.5876 25.5401 12.7268 25.5306 12.8564 25.5114C12.8564 25.5114 13.1732 25.4682 13.49 25.3434C14.1188 25.1082 14.6995 24.7147 15.1891 24.3115C16.0819 23.5771 16.6771 22.7898 16.6771 22.7898C18.1651 20.8219 18.5971 18.7724 18.8611 17.4909C18.8611 17.4861 18.8659 17.4812 18.8659 17.4716C19.0099 16.7709 19.0771 16.0556 19.0771 15.3404V15.134C13.4612 15.0732 11.3677 12.4426 10.6207 10.8927Z"
            stroke="#E482FF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
          <path d="M20.4163 27.4406C20.3971 27.4454 20.3731 27.4502 20.3491 27.4502" stroke="#E482FF" stroke-width="1.5"
                stroke-miterlimit="10" stroke-linecap="round"/>
          <path d="M8.76191 26.6775L4.46118 27.4503" stroke="#E482FF" stroke-width="1.5" stroke-miterlimit="10"
                stroke-linecap="round"/>
        </svg>
      </div>
      <p class="popup-confirm__text">Являетесь ли Вы специалистом сферы здравоохранения? </p>
      <div class="popup-confirm__btns">
        <div class="popup-confirm__btn popup-confirm__btn--right" @click="switchConfirmPopup(false)">
          <p>ДА</p>
        </div>
        <div class="popup-confirm__btn popup-confirm__btn-type2" @click="stage = 2">
          <p>НЕТ</p>
        </div>
      </div>
    </div>

    <div v-else class="content">
      <div class="popup-confirm__icon popup-confirm__icon--icon2">
        <svg width="7" height="24" viewBox="0 0 7 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M6.13158 12.8148C6.13158 14.3297 4.83137 15.5538 3.22241 15.5538C1.60239 15.5538 0.28494 14.3133 0.284937 12.7881L0.284912 2.97018C0.28491 1.45534 1.58509 0.231201 3.19404 0.231201C4.81408 0.231201 6.13158 1.47164 6.13158 2.99694V12.8148ZM3.21493 14.0996C3.97383 14.0997 4.5871 13.5223 4.5871 12.8078L4.5871 2.98987C4.5871 2.27043 3.96567 1.68535 3.20155 1.68535C2.44265 1.68535 1.8294 2.26274 1.8294 2.97725L1.82942 12.7951C1.82942 13.5146 2.45083 14.0996 3.21493 14.0996ZM5.99893 21.2275C5.99893 22.6928 4.74131 23.8768 3.18507 23.8767C1.6182 23.8767 0.343976 22.6769 0.343954 21.2017L0.343953 21.1016C0.343932 19.6365 1.60149 18.4524 3.1577 18.4524C4.72462 18.4524 5.99893 19.6522 5.99893 21.1275V21.2275ZM3.17763 22.4226C3.88378 22.4226 4.45444 21.8853 4.45444 21.2205V21.1204C4.45444 20.451 3.87621 19.9066 3.1652 19.9066C2.45906 19.9066 1.88843 20.4439 1.88844 21.1087L1.88844 21.2088C1.88845 21.8782 2.46664 22.4225 3.17763 22.4226Z"
                fill="white"/>
        </svg>
      </div>
      <p class="popup-confirm__text">К сожалению, информация предназначена только для специалистов сферы
        здравоохранения </p>

      <div class="popup-confirm__btns">
        <div class="popup-confirm__btn popup-confirm__btn--single" @click="reload">
          <p>Выход</p>
        </div>

      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.popup-confirm {
  position: fixed;

  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  z-index: 9999999;

  padding: 20px;

  background: rgba(50, 12, 70, 0.7);

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100vh;

  .content {
    border: 2px solid rgba(255, 255, 255, 0.15);
    background: rgba(255, 255, 255, 0.10);
    backdrop-filter: blur(66px);

    max-height: 400px;
    height: 100%;

    padding: 60px 40px 30px 40px;

    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 728px;
    width: 100%;
    @media screen and (max-width: 600px) {
      align-items: flex-start;
      padding: 20px 20px 30px 20px;
    }
  }

  &__icon {

    width: 44px;
    height: 44px;

    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(65, 20, 61, 0.5) 0%, rgba(39, 7, 80, 0.5) 100%);
    box-shadow: 0px 6.4px 4.26667px rgba(10, 5, 32, 0.1), inset 0px 0px 16px rgba(228, 130, 255, 0.25);
    backdrop-filter: blur(5.33333px);
    border-radius: 12px;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 10px;

    svg {
      width: 24px;
      height: auto;
      display: block;
    }

    &--icon2 {

      background: linear-gradient(138.65deg, rgba(228, 130, 255, 0.7) 0%, rgba(41, 164, 204, 0) 107.25%);
      box-shadow: inset 0px 0px 16px rgba(130, 255, 233, 0.25);
      filter: drop-shadow(0px 6.4px 4.26667px rgba(10, 5, 32, 0.1));
      border-radius: 12px;


      svg {
        width: 7px;
      }
    }

  }

  &__text {
    color: var(--Text-White, #FFF);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    @media screen and (max-width: 600px) {
      text-align: left;
    }
  }

  &__btns {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: auto;
    @media screen and (max-width: 600px) {
      justify-content: flex-start;
    }
  }

  &__btn {


    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 10px;

    max-width: 200px;
    width: 100%;
    height: 48px;

    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(94.58deg, rgba(195, 129, 220, 0.35) 0%, rgba(77, 214, 255, 0.35) 100%);
    box-shadow: inset 0 0 15px rgba(255, 255, 255, 0.44);
    position: relative;

    overflow: hidden;

    @media screen and (max-width: 600px) {
      max-width: 100%;
    }

    &--right {
      margin-right: 16px;
    }

    &--single {
      @media screen and (max-width: 600px) {
        max-width: 132px;
      }
    }

    &:before {
      content: '';

      position: absolute;
      height: 3px;
      width: 100%;
      left: 0;
      right: 0;
      bottom: 0;

      background: linear-gradient(90deg, #D84CFF 0%, #00CFFF 100%);
      border-top: 1px solid rgba(255, 255, 255, 0.5);

    }

    &:after {
      content: '';

      position: absolute;
      height: 124px;
      left: 0;
      right: 0;
      top: 90%;

      background: linear-gradient(90deg, #E482FF 0%, #3ED2F4 100%);
      filter: blur(13px);
      border-radius: 500px;


    }

    p {
      color: var(--Text-White, #FFF);
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0.28px;
      text-transform: uppercase;

    }

    @media screen and (min-width: 1024px) {
      &:not(.active) {

        &:hover {
          user-select: none;
          cursor: pointer;

          p {
            color: var(--Accent-Purple, #E482FF);
          }
        }
      }
    }

    &:active {
      user-select: none;
      background: rgba(228, 130, 255, 0.10);
      cursor: pointer;

      p {
        color: #ffffff !important;
      }

      &:before {
        opacity: 0;
      }

      &:after {
        opacity: 0;
      }
    }

    &-type2 {

      &:after {

        background: linear-gradient(80.28deg, #E482FF 7.31%, #FF00C7 46.09%, #3ED2F4 108.79%);
        filter: blur(10.2583px);
        border-radius: 40px;

      }
    }
  }
}
</style>