import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '@/views/HomeView.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        props: true
    },
    {
        path: '/article',
        name: 'article',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.

        component: () => import("../views/ArticleView.vue"),
        props: true
    },
    {
        path: '/exercises',
        name: 'exercises',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.

        component: () => import("../views/ExercisesView.vue"),
        props: true
    },
    {
        path: '/interactive',
        name: 'interactive',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.

        component: () => import("../views/InteractiveView.vue"),
        props: true
    },
    {
        path: '/quiz',
        name: 'quiz',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("../views/QuizView.vue"),
        props: true
    },
    {
        path: '/video',
        name: 'video',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("../views/VideoView.vue"),

        props: true

    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior: () => {
        return {x: 0, y: 0}
    },
})

export default router
