<script>

</script>

<template>
  <div class="bottom">

    <a href="https://olpha.eu/" target="_blank">
      <img class="bottom__logo" src="@/assets/img/ui/bottom/logo.svg" alt="logo-olainFarm">
    </a>
    <p class="bottom__copy">© Copyright 2024 OlainFarm</p>
  </div>
</template>

<style scoped lang="scss">
.bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  max-width: 1440px;
  width: 100%;

  margin: 0 auto 0 auto;

  padding: 9px 60px;

  @media screen and (max-width: 1024px) {
    padding: 9px 20px;
  }

  &__logo {
    width: 61px;

    height: auto;
    display: block;
  }

  &__copy {
    color: var(--Brand-Light, #C298DC);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: 'MetaPro', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }
}
</style>