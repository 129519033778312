<script>
import {mapActions} from "vuex";

export default {
  data() {
    return {
      showCookiesBanner: true,
      targetCookies: false,
      functionalCookies: false,
      operationalCookies: false,
    };
  },
  methods: {
    ...mapActions(['switchCookiesPopup']),
    hideBanner() {
      this.showCookiesBanner = false;
      this.switchCookiesPopup(false)
      localStorage.setItem('cookies', this.showCookiesBanner);
    },
    // end banner nav
    setCookie(name, value, options = {}) {
      options = {
        path: "/",
        // при необходимости добавьте другие значения по умолчанию
        ...options,
      };

      if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
      }

      let updatedCookie =
        encodeURIComponent(name) + "=" + encodeURIComponent(value);

      for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
          updatedCookie += "=" + optionValue;
        }
      }

      document.cookie = updatedCookie;
    },
    getCookie(name) {
      let matches = document.cookie.match(
        new RegExp(
          "(?:^|; )" +
          name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
          "=([^;]*)"
        )
      );
      return matches ? decodeURIComponent(matches[1]) : undefined;
    },
    deleteCookie(name) {
      this.setCookie(name, "", {
        "max-age": -1,
      });
    },
    cookiesDelete() {
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;";
        document.cookie = name + '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      }
    },

    acceptAll() {

      this.targetCookies = true;
      this.functionalCookies = true;
      this.operationalCookies = true;

      this.hideBanner();
      this.acceptCookies();


      // setTimeout(() => {
      //   window.location.reload()
      // }, 1)

    },
    accept() {
      this.hideBanner();
      this.acceptCookies();


      // setTimeout(() => {
      //   window.location.reload()
      // }, 1)

    },
    acceptCookies() {
      this.cookiesDelete();
      let expires = new Date(new Date().valueOf() + 30000 * 60 * 60 * 24 * 15);
      document.cookie = "neiromidin-targetCookies=" + this.targetCookies + "; expires=" + expires;
      document.cookie = "neiromidin-functionalCookies=" + this.functionalCookies + "; expires=" + expires;
      document.cookie = "neiromidin-operationalCookies=" + this.operationalCookies + "; expires=" + expires;

      if (!this.showCookiesBanner) {
        let expires = new Date(new Date().valueOf() + 30000 * 60 * 60 * 24 * 15);
        document.cookie = "neiromidin-scb=false; expires=" + expires;
      }

      if (this.targetCookies) {

      }

      if (this.functionalCookies) {
        // -
        // console.log("functionalCookies");
      }

      if (this.operationalCookies) {
        // console.log("operationalCookies");
        // аналитике Гугла, Тег менеджера Гугла и Хотжар.

        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({
            'gtm.start':
              new Date().getTime(), event: 'gtm.js'
          });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-MJL4CX5Z');
        // <!-- Global site tag (gtag.js) - Google Analytics -->


      } else {
        // delete window.dataLayer;
        // window.gtag = function(){
        //   // stub function
        // }
        const cyrb53 = function (str, seed = 0) {
          let h1 = 0xdeadbeef ^ seed,
            h2 = 0x41c6ce57 ^ seed;
          for (let i = 0, ch; i < str.length; i++) {
            ch = str.charCodeAt(i);
            h1 = Math.imul(h1 ^ ch, 2654435761);
            h2 = Math.imul(h2 ^ ch, 1597334677);
          }
          h1 = Math.imul(h1 ^ h1 >>> 16, 2246822507) ^ Math.imul(h2 ^ h2 >>> 13, 3266489909);
          h2 = Math.imul(h2 ^ h2 >>> 16, 2246822507) ^ Math.imul(h1 ^ h1 >>> 13, 3266489909);
          return 4294967296 * (2097151 & h2) + (h1 >>> 0);
        };

        let clientIP = fetch('https://api.ipify.org?format=json')
          .then(x => x.json())
          .then(({ip}) => {
            return ip;
          });
        ;
        let validityInterval = Math.round(new Date() / 1000 / 3600 / 24 / 4);
        let clientIDSource = clientIP + ";" + window.location.host + ";" + navigator.userAgent + ";" + navigator.language + ";" + validityInterval;
        let clientIDHashed = cyrb53(clientIDSource).toString(16);

        // window.dataLayer = window.dataLayer || [];
        //
        // gtag('js', new Date());

      }
    }

  },
  mounted() {

    if (localStorage.getItem('cookies') !== null) {
      this.showCookiesBanner = false
    }
    if (!this.showCookiesBanner) {
      this.showCookiesBanner = this.getCookie("neiromidin-scb") !== "false" ? true : false;

      this.targetCookies = this.getCookie('neiromidin-targetCookies') === "true" ? true : false;
      this.functionalCookies = this.getCookie('neiromidin-functionalCookies') === "true" ? true : false;
      this.operationalCookies = this.getCookie('neiromidin-operationalCookies') === "true" ? true : false;

      this.acceptCookies();
    }

  },
};
</script>

<template>
  <div class="cookies" v-show="showCookiesBanner">
    <div class="content">
      <div class="cookies__icon">
        <svg width="24" height="29" viewBox="0 0 24 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M18.7076 3.43667L18.2852 7.73254H6.10789L5.64232 3.46058C5.56072 2.731 6.02626 2.05429 6.73184 1.8623L7.73505 1.59348C10.7062 0.791897 13.8357 0.801529 16.7972 1.62711L17.6324 1.8623C18.3236 2.05429 18.7748 2.72149 18.7076 3.43667Z"
            stroke="#E482FF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
          <path d="M12.2371 2.87988V5.85578" stroke="#E482FF" stroke-width="1.5" stroke-miterlimit="10"
                stroke-linecap="round"/>
          <path d="M13.7154 4.36768H12.3618" stroke="#E482FF" stroke-width="1.5" stroke-miterlimit="10"
                stroke-linecap="round"/>
          <path d="M12.2371 4.36768H10.7395" stroke="#E482FF" stroke-width="1.5" stroke-miterlimit="10"
                stroke-linecap="round"/>
          <path
            d="M4.65823 7.34863C3.61665 8.89419 3.01665 10.7517 3.01665 12.7293C3.01665 13.6029 3.0503 14.5196 3.12709 15.4844C3.35269 18.2971 3.89503 20.7595 4.51901 22.8042C4.9126 24.1098 3.70307 25.377 2.3975 24.9786V24.9786C1.96164 24.8492 1.51092 25.1443 1.70836 25.5539C2.06566 26.2951 2.82431 27.191 4.42784 27.4458L8.81974 26.5962C8.96374 26.5386 9.07893 26.457 9.17493 26.3562C9.24692 26.289 9.30453 26.2026 9.35732 26.1162C9.39092 26.0586 9.41489 26.0009 9.43888 25.9385C9.46288 25.8761 9.47728 25.8138 9.49168 25.7514C9.52528 25.593 9.53972 25.425 9.53972 25.2762C9.53972 25.209 9.53491 25.1466 9.53011 25.089C9.53011 25.0362 9.5253 24.9833 9.5157 24.9401C9.5013 24.8201 9.48211 24.7098 9.46771 24.633"
            stroke="#E482FF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
          <path
            d="M15.3138 24.6085C15.3138 24.6229 15.309 24.6373 15.309 24.6469C15.3042 24.6805 15.3042 24.7045 15.2994 24.7429C15.2946 24.7717 15.2898 24.7957 15.2898 24.8293C15.285 24.8629 15.285 24.9013 15.2802 24.9397C15.2514 25.4773 15.333 26.3029 16.0434 26.5909L20.4113 27.4357C21.9963 27.1738 22.7463 26.2851 23.0998 25.549C23.2979 25.1364 22.8468 24.843 22.408 24.9733V24.9733C22.2928 25.0117 22.024 25.0694 21.7408 25.0406C21.5872 25.0262 21.4432 24.9877 21.3136 24.9349C20.6848 24.6709 20.3105 24.0086 20.3393 23.3222L20.4065 21.7718L21.1553 19.295C21.3856 18.1094 21.568 16.8327 21.6784 15.4743C21.7552 14.5096 21.7888 13.5928 21.7888 12.7192C21.7888 10.5209 21.0401 8.47611 19.7921 6.83936"
            stroke="#E482FF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
          <path
            d="M10.6207 10.8927C10.5229 10.6897 9.96317 10.6985 9.87734 10.9069C9.38349 12.1057 8.25699 13.9779 5.81982 15.0669V15.3452C5.81982 16.0508 5.87739 16.7468 6.02138 17.438C6.02618 17.4572 6.03099 17.4763 6.03099 17.4955C6.49178 19.7035 7.36534 21.4987 8.22931 22.8667C8.53651 23.3035 8.98294 23.8554 9.59252 24.3498C10.0869 24.7482 10.6821 25.113 11.4021 25.3482C11.6181 25.4202 11.8292 25.473 12.0356 25.5114C12.1652 25.5306 12.2996 25.5401 12.4436 25.5401C12.5876 25.5401 12.7268 25.5306 12.8564 25.5114C12.8564 25.5114 13.1732 25.4682 13.49 25.3434C14.1188 25.1082 14.6995 24.7147 15.1891 24.3115C16.0819 23.5771 16.6771 22.7898 16.6771 22.7898C18.1651 20.8219 18.5971 18.7724 18.8611 17.4909C18.8611 17.4861 18.8659 17.4812 18.8659 17.4716C19.0099 16.7709 19.0771 16.0556 19.0771 15.3404V15.134C13.4612 15.0732 11.3677 12.4426 10.6207 10.8927Z"
            stroke="#E482FF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
          <path d="M20.4163 27.4404C20.3971 27.4452 20.3731 27.45 20.3491 27.45" stroke="#E482FF" stroke-width="1.5"
                stroke-miterlimit="10" stroke-linecap="round"/>
          <path d="M8.76191 26.6772L4.46118 27.4501" stroke="#E482FF" stroke-width="1.5" stroke-miterlimit="10"
                stroke-linecap="round"/>
        </svg>
      </div>
      <p class="cookies__title">Сайт использует куки</p>
      <p class="cookies__text">Наш сайт использует файлы куки (cookies), чтобы улучшить работу сайта, повысить
        его эффективность и удобство. Продолжая использовать сайт, Вы соглашаетесь
        на использование файлов куки. <a href="https://olpha.eu/politika-ispolzovaniya-fajlov-kuki/"
                                         target="_blank">Подробнее</a></p>
      <div class="cookies__btns">
        <div class="cookies__btn cookies__btn--right" @click="acceptAll(); $emit('closePopup')">
          <p>Принять все</p>
        </div>
        <div class="cookies__btn cookies__btn-type2" @click="accept(); $emit('closePopup')">
          <p>Только необходимое</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.cookies {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;

  background: rgba(50, 12, 70, 0.70);
  z-index: 400;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 20px;

  .content {
    max-width: 768px;
    width: 100%;
    padding: 60px 40px 30px 40px;

    border: 2px solid rgba(255, 255, 255, 0.15);
    background: rgba(255, 255, 255, 0.10);
    backdrop-filter: blur(66.66667175292969px);
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @media screen and (max-width: 600px) {
      text-align: left;
      align-items: flex-start;
      padding: 20px 20px 30px 20px;
    }
  }

  &__title {
    color: var(--Text-White, #FFF);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 10px;
  }

  &__text {
    color: var(--Text-White, #FFF);
    font-family: 'MetaPro', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    margin-bottom: 62px;
    @media screen and (max-width: 600px) {
      margin-bottom: 38px;
    }

    a {
      color: var(--Accent-Purple, #E482FF);
      font-family: 'MetaPro', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      text-decoration-line: underline;
      @media screen and (min-width: 1024px) {
        &:hover {
          text-decoration-line: none;
        }
      }

      &:active {
        text-decoration-line: none;
      }
    }
  }

  &__icon {

    width: 44px;
    height: 44px;

    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(65, 20, 61, 0.5) 0%, rgba(39, 7, 80, 0.5) 100%);
    box-shadow: 0px 6.4px 4.26667px rgba(10, 5, 32, 0.1), inset 0px 0px 16px rgba(228, 130, 255, 0.25);
    backdrop-filter: blur(5.33333px);
    border-radius: 12px;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 10px;

    svg {
      width: 24px;
      height: auto;
      display: block;
    }

    &--icon2 {

      background: linear-gradient(138.65deg, rgba(228, 130, 255, 0.7) 0%, rgba(41, 164, 204, 0) 107.25%);
      box-shadow: inset 0px 0px 16px rgba(130, 255, 233, 0.25);
      filter: drop-shadow(0px 6.4px 4.26667px rgba(10, 5, 32, 0.1));
      border-radius: 12px;


      svg {
        width: 7px;
      }
    }

  }

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    width: 48px;
    height: 48px;
    right: 0;
    top: 0;

    border: 1px solid rgba(255, 255, 255, 0.1);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.00) 100%);
    box-shadow: 0px 0px 24px 0px rgba(228, 130, 255, 0.25) inset, 0px 20.8px 16px 0px rgba(10, 5, 32, 0.17);
    backdrop-filter: blur(16px);

    @media screen and (min-width: 1024px) {
      &:hover {
        user-select: none;
        cursor: pointer;

        background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
        border: 1.2px solid rgba(255, 255, 255, 0.3);
        box-shadow: inset 0px 0px 24px rgba(228, 130, 255, 0.25);
        filter: drop-shadow(0px 20.8px 16px rgba(10, 5, 32, 0.17));
        backdrop-filter: blur(16px);

        svg {
          path {
            stroke: var(--Accent-Purple, #E482FF);
          }
        }

        &:before {
          opacity: 1;
        }
      }
    }

    &:active {
      user-select: none;
      border: 1px solid rgba(255, 255, 255, 0.1);
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
      filter: drop-shadow(0px 20.8px 16px rgba(10, 5, 32, 0.17));
      backdrop-filter: blur(16px);

      svg {
        path {
          stroke: var(--Accent-Purple, #E482FF);
        }
      }

      &:before {
        opacity: 0;
      }
    }

    &:before {
      content: '';

      opacity: 0;

      box-sizing: border-box;

      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      right: 0;
      bottom: 0;

      background: linear-gradient(90deg, #D84CFF 0%, #00CFFF 100%);
      border-top: 0.45px solid rgba(255, 255, 255, 0.5);

    }

    svg {
      width: 17px;
      height: auto;
      display: block;
    }
  }

  &__btns {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: auto;
    @media screen and (max-width: 600px) {
      justify-content: flex-start;
    }
  }

  &__btn {


    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 10px;

    max-width: 200px;
    width: 100%;
    height: 48px;

    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(94.58deg, rgba(195, 129, 220, 0.35) 0%, rgba(77, 214, 255, 0.35) 100%);
    box-shadow: inset 0 0 15px rgba(255, 255, 255, 0.44);
    position: relative;

    overflow: hidden;

    @media screen and (max-width: 600px) {
      max-width: 100%;
    }

    &--right {
      margin-right: 16px;
    }

    &--single {
      @media screen and (max-width: 600px) {
        max-width: 132px;
      }
    }

    &:before {
      content: '';

      position: absolute;
      height: 3px;
      width: 100%;
      left: 0;
      right: 0;
      bottom: 0;

      background: linear-gradient(90deg, #D84CFF 0%, #00CFFF 100%);
      border-top: 1px solid rgba(255, 255, 255, 0.5);

    }

    &:after {
      content: '';

      position: absolute;
      height: 124px;
      left: 0;
      right: 0;
      top: 80%;

      background: linear-gradient(90deg, #E482FF 0%, #3ED2F4 100%);
      filter: blur(13px);
      border-radius: 500px;


    }

    p {
      color: var(--Text-White, #FFF);
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0.28px;
      text-transform: uppercase;

    }

    @media screen and (min-width: 1024px) {
      &:not(.active) {

        &:hover {
          user-select: none;
          cursor: pointer;

          p {
            color: var(--Accent-Purple, #E482FF);
          }
        }
      }
    }

    &:active {
      user-select: none;
      background: rgba(228, 130, 255, 0.10);
      cursor: pointer;

      p {
        color: #ffffff !important;
      }

      &:before {
        opacity: 0;
      }

      &:after {
        opacity: 0;
      }
    }

    &-type2 {

      &:after {

        background: linear-gradient(80.28deg, #E482FF 7.31%, #FF00C7 46.09%, #3ED2F4 108.79%);
        filter: blur(10.2583px);
        border-radius: 40px;

      }
    }
  }
}
</style>
